import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import categoriesService from "../../services/Home";
import { useQuery } from "@tanstack/react-query";

const Categories = () => {
  const { data: allCategories, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => categoriesService.category(),
    select: (data) => data?.data,
  });

  const shimmerItems = Array(10).fill(null);

  return (
    <>
      <Helmet>
        <title>Impel Store - All Categories</title>
      </Helmet>
      <section className="categories">
        <div className="container">
          <div className="categories_header">
            <h3>Categories</h3>
          </div>
          {isLoading ? (
            <div className="row">
              {shimmerItems.map((_, index) => (
                <div className="col-lg-3 col-md-6 col-12" key={index}>
                  <div className="shimmer-product">
                    <div className="shimmer-image"></div>
                    <div className="shimmer-price"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="categories_data_main">
              <div className="row">
                {allCategories?.map((data, index) => {
                  return (
                    <div className="col-lg-3 col-md-6 col-12" key={index}>
                      <Link
                        to={`/categories/${data.id}`}
                        className="text-decoration-none"
                        style={{ color: "#000" }}
                      >
                        <div className="category_data_item">
                          <img src={data?.image} className="w-100" alt="" />
                          <div className="product_details">
                            <h4 className="fw-bolder">{data?.name}</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Categories;
